import type { DataObject, ItemModel, RecordSourceOptions } from 'o365-dataobject';
import type { FilterItem } from 'o365-filterobject';
import { filterUtils, Devex} from 'o365-filterobject'
import { nextTick } from 'vue';

const { createInitialObject, filterItemsToString } = filterUtils;

export default class DistinctPropertiesHanlder<T extends ItemModel = ItemModel> {
    private _dataObject: DataObject<T>;

    private _valueSortDirection: 'asc' | 'desc' = 'desc';
    private _countSortDirection: 'asc' | 'desc' = 'desc';
    private _sortType: 'value' | 'count' = 'count';
    private _searchString?: string;
    private _isLoading = false;
    private _propertyName: string;
    private _data: { Value: string, Count: number, isSelected?: boolean, isUnAllocated?: boolean }[] = [];
    private _filteredData: DistinctPropertiesHanlder<T>['_data'] = [];
    private _updated = new Date();
    private _previousRequestKey?: string;
    private _changedDate?: Date;

    selectedValues: Set<string> = new Set();

    get valueSortDirection() { return this._valueSortDirection; }
    set valueSortDirection(pValue) { this._valueSortDirection = pValue; }
    get countSortDirection() { return this._countSortDirection; }
    set countSortDirection(pValue) { this._countSortDirection = pValue; }

    get sortType() { return this._sortType; }
    set sortType(pValue) { this._sortType = pValue; }
    get searchString() { return this._searchString; }
    set searchString(pValue) { this._searchString = pValue; }

    get isLoading() { return this._isLoading; }
    get data() { return this._filteredData; }
    get updated() { return this._updated; }

    constructor(pDataObject: DataObject<T>, pOptions: {
        propertyName: string
    }) {
        if (!pDataObject.hasPropertiesData) {
            throw new TypeError(`Cannot create DistinctPropertiesHanlder for DataObject that doesn't have properties extension enabled`);
        }
        this._dataObject = pDataObject;
        this._propertyName = pOptions.propertyName;
    }

    /** Get distinct values for a property */
    async load() {
        this._isLoading = true;
        const propertyNameField = this._dataObject.propertiesData.propertyField;
        const sortOnValue = this._sortType === 'value';
        const options = this._dataObject.recordSource.getOptions();
        const propertiesOptions = {} as Partial<RecordSourceOptions>;
        const exsitsClauses: string[] = [];
        let vReload = false;


        if(this._changedDate && this._dataObject?.propertiesData && this._dataObject.propertiesData.changedDate && this._changedDate < this._dataObject.propertiesData.changedDate){
            vReload = true;
        }
        if (options.definitionProc) {
            propertiesOptions.definitionProc = this._dataObject.propertiesData.propertyDefintionProc;
            propertiesOptions.contextId = options.contextId;
            propertiesOptions.viewName = this._dataObject.propertiesData.distinctPropertiesView ?? this._dataObject.propertiesData.propertiesDataObject.viewName;
            if (this._dataObject.recordSource.sqlStatementParameters && this._dataObject.recordSource.sqlStatementParameters.hasOwnProperty('Register_ID')) {
                propertiesOptions.sqlStatementParameters = { Register_ID: this._dataObject.recordSource.sqlStatementParameters.Register_ID}
            }
        }
        let clausesArray: string[] = [];
        if (options.whereClause) {
            if (options.whereClause.includes('exists_clause')) {
                // Remove exists clauses from the whereclause and attempt to map them on the properties view instead (avoid nested exists_clauses)
                const whereObject = await filterUtils.filterStringToFilterItems(options.whereClause);
                const existsExpressions: any[] = [];
                const removeExistsClause = (pObj: any) => {
                    if (pObj.type === 'function') {
                        existsExpressions.push(pObj);
                        return true;
                    } else if (pObj.type === 'group') {
                        const toRemove: number[] = [];
                        pObj.items.forEach((obj: any, index: number) => {
                            if (removeExistsClause(obj)) {
                                toRemove.unshift(index);
                            }
                        });
                        toRemove.forEach(index => pObj.items.splice(index, 1));
                    }
                    return false;
                }
                removeExistsClause(whereObject);
                options.whereClause = filterItemsToString(whereObject);
                existsExpressions.forEach(expression => {
                    const viewName = expression.items[0].name;
                    const binding = this._dataObject.propertiesData.distinctPropertiesExistsClauseBindings[viewName];
                    if (binding == null) { return; }
                    if (expression.items[2]) {
                        const expressionClause = expression.items[2].type === 'group'
                            ? Devex.sqlifyGroup(expression.items[2])
                            : Devex.sqlifyExpression(expression.items[2]);
                        exsitsClauses.push(`${expression.name}(${viewName}, ${binding}, ${expressionClause})`);
                    } else {
                        exsitsClauses.push(`${expression.name}(${viewName}, ${binding})`);
                    }
                });
            }
            clausesArray.push(`${options.whereClause}`);
        }
        if (options.filterString) {
            const filters = Object.values((this._dataObject.filterObject as any).filterItems)
                .filter((x: any) => !x.column?.startsWith('Property.') && (!x.excluded && x.selectedValue != null) || x.type == 'group')
                .map((item: any) => item.type == 'group' ? item : item.item);
            const filterObject = createInitialObject(null);
            filterObject.items[0]['items'] = filters;
            const filterString = filterItemsToString(filterObject);
            if (filterString) {
                clausesArray.push(`(${filterString})`);
            }
        }
        const filterString = clausesArray.length > 0
            ? `exists_clause(${this._dataObject.propertiesData.configView}, T2.${this._dataObject.propertiesData.itemIdField} = T1.${this._dataObject.propertiesData.propertyIdField}, ${clausesArray.join(' AND ')})`
            : `exists_clause(${this._dataObject.propertiesData.configView}, T2.${this._dataObject.propertiesData.itemIdField} = T1.${this._dataObject.propertiesData.propertyIdField})`;
        try {
            let whereClause = `[${propertyNameField}] = '${this._propertyName}'`;
            if (this._dataObject.propertiesData.propertiesWhereClause) {
                whereClause = `${this._dataObject.propertiesData.propertiesWhereClause} AND ${whereClause}`;
            }
            if (exsitsClauses.length > 0) {
                whereClause += `AND ${exsitsClauses.join(' AND ')}`
            }
            let requestKey = `f:${filterString};w:${whereClause}s:${this.sortType}-${this.sortType === 'value' ? this.valueSortDirection : this.countSortDirection}`;
            if (Object.keys(propertiesOptions).length > 0) {
                requestKey += `;c:${propertiesOptions.contextId}`;
            }
            this.selectedValues.clear();
            if (this._previousRequestKey !== requestKey || vReload) {
                this._previousRequestKey = requestKey;
                const data = await this._dataObject.propertiesData.propertiesDataObject.recordSource.retrieve({
                    fields: [
                        {
                            name: 'Value',
                            groupByOrder: 1,
                            sortOrder: sortOnValue ? 1 : undefined,
                            sortDirection: sortOnValue ? this._valueSortDirection : undefined
                        },
                        {
                            name: 'PrimKey',
                            alias: 'Count',
                            aggregate: 'COUNT',
                            sortOrder: sortOnValue ? undefined : 1,
                            sortDirection: sortOnValue ? undefined : this._countSortDirection
                        }
                    ],
                    distinctRows: true,
                    maxRecords: -1,
                    skip: 0,
                    filterString: filterString,
                    whereClause: whereClause,
                    ...propertiesOptions
                });
                this._changedDate = new Date()
                const unallocatedOptions = this._dataObject.recordSource.getOptions();
                unallocatedOptions.fields = [{ name: this._dataObject.fields.uniqueField ?? 'PrimKey', alias: 'Count', aggregate: 'COUNT' }];
                const unAllocatedWhereClause = `NOT exists_clause(${this._dataObject.propertiesData.viewName}, T2.[${this._dataObject.propertiesData.propertyIdField}] = T1.[${this._dataObject.propertiesData.itemIdField}], [${propertyNameField}] = '${this._propertyName}')`;
                if (unallocatedOptions.whereClause) {
                    unallocatedOptions.whereClause += ` AND ${unAllocatedWhereClause}`;
                } else {
                    unallocatedOptions.whereClause = unAllocatedWhereClause;
                }
                const unallocatedData = await this._dataObject.recordSource.retrieve(unallocatedOptions);

                const blankIndex = data.findIndex(x => x.Value == null);
                const blankItem = blankIndex !== -1
                    ? data.splice(blankIndex, 1)[0]
                    : undefined;

                this._data.splice(0, this._data.length, ...data as any);
                
                const unallocatedCount = unallocatedData[0]?.Count ?? 0
                if (unallocatedCount) {
                    this._data.unshift({
                        Value: '[-unallocated-]',
                        Count: unallocatedCount,
                        isUnAllocated: true,
                    })
                }
                if (blankItem) {
                    this._data.unshift(blankItem as any);
                }

                if (unallocatedCount || blankItem) {
                    this._data.sort((a, b) => {
                        if (this.sortType == 'count') {
                            return this.countSortDirection == 'asc'
                                ? a.Count - b.Count
                                : b.Count - a.Count
                        } else { 
                            return this.valueSortDirection == 'asc'
                                ?  a.Value.toString().localeCompare(b.Value.toString(), undefined, { numeric: true, sensitivity: 'base' })
                                :  b.Value.toString().localeCompare(a.Value.toString(), undefined, { numeric: true, sensitivity: 'base' });
                        }
                    });
                }
            }
        } catch (ex) {
            console.error(ex);
        } finally {
            const item = this._getFilterItem();
            const hasExpression = item.expressionValue && item.expressionValue.constructor == Array;
            this._data.forEach(row => {
                row.isSelected = false;
                if (this.selectedValues.has(row.Value)) {
                    row.isSelected = true;
                } else if (hasExpression) {
                    row.isSelected = (item.expressionValue as string[]).includes(row.Value ?? '');
                    if (row.isSelected && !this.selectedValues.has(row.Value ?? '')) {
                        this.selectedValues.add(row.Value ?? '');
                    }
                }
            });

            if (this._searchString) {
                this._filteredData.splice(0, this._filteredData.length, ...this._data.filter(x => (x.Value ?? '').toLowerCase().includes(this._searchString!.toLowerCase())))
            } else {
                this._filteredData.splice(0, this._filteredData.length, ...this._data)
            }

            this._isLoading = false;
            this.update();
        }
    }

    async update() {
        await nextTick();
        this._updated = new Date();
    }

    private _getFilterItem(): FilterItem {
        return (this._dataObject.filterObject as any).filterItems[`Property.${this._propertyName}`];
    }
}